@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700&display=swap");

@tailwind base;
@tailwind components;

.PhoneInputInput {
  border: transparent;
  /* border-bottom: 1px solid black; */
  padding: 3px 0;
}

.PhoneInput {
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 4px 4px;
}

.PhoneInputInput {
  border: transparent;
}

.date-picker {
  display: inline-block;
  min-height: 2.5rem;
  position: relative;
  isolation: isolate;
  @apply border border-gray-300 font-light bg-white rounded w-full;
}

.date-picker,
.date-picker > * {
  cursor: text;
}

.date-picker:hover {
  background: #fff;
}

.date-picker:active {
  background: #fff;
}

.date-picker:focus > input[type="date"],
.date-picker:focus-within > input[type="date"] {
  color: #000;
}

.date-picker > .placeholder::after {
  content: "Select Date of Birth";
  pointer-events: none;
  position: absolute;
  inset: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  @apply text-black text-sm pl-2;
}

.date-picker:focus > .placeholder,
.date-picker:focus-within > .placeholder,
.date-picker > input[type="date"]:valid + .placeholder {
  display: none;
}

.date-picker > input[type="date"] {
  background: none;
  border: none;
  outline: none;
  color: transparent;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @apply pl-2 font-sans text-sm pr-2;
}

.date-picker > input[type="date"]:valid {
  color: #000 !important;
}

@tailwind utilities;
